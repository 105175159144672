<!--
  Component that displays a waitlist form for users to enter their first name and email to be added to the waitlist.
  Their info is stored in AllWaitlist table in the database.
  When info is submitted, a confirmation email is sent to the user.
-->
<template>
  <div class="container">
    <h1><img :srcset="$app.productLogoTitle"/></h1>
    <div v-if="submitted">
      <p>Thank you for joining the waitlist!</p>
    </div>
    <div v-else>
      <p>Join the waitlist.</p>
      <b-form>
        <b-form-group label="First Name:" label-for="firstName">
          <b-form-input id="firstName" v-model="firstName" required></b-form-input>
        </b-form-group>
        <b-form-group label="Email:" label-for="email">
          <b-form-input type="email" id="email" v-model="email" required></b-form-input>
        </b-form-group>
        <b-button @click="submitForm()">Submit</b-button>
      </b-form>
    </div>
  </div>
</template>

<script>
import { db } from '@/services/db';

export default {
  data() {
    return {
      submitted: false,
      firstName: '',
      email: ''
    };
  },
  methods: {
    submitForm() {
      // Add info to firestore db /AllWaitlist collection
      db.collection(`AllWaitlists/${this.$app.productNameLC}/users`).add({
        firstName: this.firstName,
        email: this.email
      }).then(() => {
        this.submitted = true;
      });
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  font-size: 16px;
  margin-bottom: 5px;
}

input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>