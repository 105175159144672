<template>
<div>
  <div v-if="$app.waitlist">
    <Waitlist/>
  </div>
  <div v-else>
    Page not found
  </div>
  <my-footer/>
</div>
</template>

<script>
import Vue from 'vue';
import { getLog } from '@/services/log';
import Footer from '@/components/Footer.vue';
import Waitlist from './Waitlist.vue';
let log = getLog("test-builder");

export default Vue.extend({
  components: { 
    MyFooter:Footer,
    Waitlist,
  },
  data() {
    return {
      notFound: false,
    }
  },
  mounted() {
    log.log("mounted");
  },
  methods: {
  },  
});
</script>